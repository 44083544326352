import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';
import useAsync from 'User/helpers/hooks/useAsync';
import {loginUser} from 'providers/user/auth';
import {setUserLoggedinMessage} from 'utils/session';
import {boardingRoute} from 'User/helpers/routes';
import {isProduction} from 'helpers/stableValues';
import useScript from 'helpers/hooks/useScript';

const SocialAccount = ({isAccountPage, isModal, btnGoogleAccountId}) => {
  const {isSuccess, data, execute} = useAsync();
  const googleAccountScript = useScript('https://accounts.google.com/gsi/client');

  useEffect(() => {
    const initGoggleAccounts = async () => {
      window.google.accounts.id.initialize({
        client_id: '1016037285519-ko39v4c8q18elpi9hovf3e1i0bdcgfcj.apps.googleusercontent.com',
        callback: async ({credential}) => {
          await execute(loginUser({
            accessToken: credential,
            email: null,
            password: null,
            provider: 'google',
            providerUserID: null
          }));
        }
      });
      window.google.accounts.id.renderButton(
        document.getElementById(btnGoogleAccountId),
        {theme: 'outline', size: 'large'}
      );
      window.google.accounts.id.prompt();
    };

    if (googleAccountScript === 'ready' && window?.google?.accounts?.id) {
      initGoggleAccounts();
    }
  }, [execute, btnGoogleAccountId, googleAccountScript]);

  useEffect(() => {
    if (isSuccess && data) {
      const {result: {uuid, session_token}} = data;
      localStorage.setItem('user', JSON.stringify({uuid, session_token}));
      Cookies.set(
        'TP24ID',
        session_token,
        {path: '/', secure: isProduction, sameSite: 'lax'}
      );

      setTimeout(() => {
        if (isAccountPage) {
          window.location = boardingRoute;
        }

        if (isModal) {
          setUserLoggedinMessage();
          location.reload();
        }
      }, 500);
    }
  }, [isSuccess, data, isAccountPage, isModal]);

  return (
    <div className="d-flex justify-content-center">
      <div id={btnGoogleAccountId} style={{height: '40px'}}/>
    </div>
  );
};

SocialAccount.propTypes = {
  isAccountPage: PropTypes.bool.isRequired,
  isModal: PropTypes.bool.isRequired,
  btnGoogleAccountId: PropTypes.string.isRequired
};

export default SocialAccount;
