import React, {memo, useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import Dropdown from 'react-bootstrap/lib/Dropdown';
import {isElementInViewport} from 'helpers/element';
import OptionsToggle from './OptionsToggle';

const DropdownSelect = ({
  label, required, value, errors, name, style, isDisabled, elementId, containerProps, children
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleOpen = () => setIsOpen((isOpen) => !isOpen);

  useEffect(() => {
    const el = document.getElementById(elementId);
    if (isOpen && el && !isElementInViewport(el, 300)) {
      el.scrollIntoView({block: 'center', behavior: 'smooth'});
    }
  }, [isOpen, elementId]);

  return (
    <Dropdown
      id={name}
      onToggle={handleOpen}
      open={!isDisabled && isOpen}
      componentClass="div"
      className="dropdown-wrapper"
    >
      <OptionsToggle
        bsRole="toggle"
        faIcon="icon-triangle-sm-down"
        value={value}
        htmlFor={name}
        errors={errors}
        label={`${label}${required ? '*' : ''}`}
        isDisabled={isDisabled}
        containerProps={containerProps}
      />
      <Dropdown.Menu className="scrollable-menu" style={style}>
        {children(handleOpen)}
      </Dropdown.Menu>
    </Dropdown>
  );
};

DropdownSelect.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  errors: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object
  ]).isRequired,
  name: PropTypes.string.isRequired,
  style: PropTypes.object.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  required: PropTypes.bool.isRequired,
  elementId: PropTypes.string,
  containerProps: PropTypes.object.isRequired,
  children: PropTypes.func.isRequired
};

DropdownSelect.defaultProps = {
  label: '',
  isDisabled: false,
  required: false,
  errors: [],
  style: {},
  containerProps: {}
};

export default memo(DropdownSelect);
