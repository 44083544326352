import qs from 'qs';
import {extendObservable, action, decorate} from 'mobx';
import {loadBeacon, openBeaconChat} from 'helpers/events';
import {addDataLayer} from 'helpers/commonHelper';
import {MOBILE_WIDTH, isAirtickets} from 'helpers/stableValues';

function eventTrengoBeacon() {
  if (((/^\/[a-z]{2}\/.+\/(results|seating|payment|confirmation|open-chat)/)
    .test(location.pathname) ||
      (/^\/[a-z]{2}\/(ferry|aktoploika|faehre|traghetti|feribot|farja|faerge|lautta|ferge|laevapiletid)$/)
        .test(location.pathname)) && (window.innerWidth > MOBILE_WIDTH)
  ) {
    addDataLayer({event: loadBeacon});
  }
  const {openChat} = qs.parse(location.search.slice(1));
  if (openChat === '1') {
    addDataLayer({event: openBeaconChat});
  }
}

class NavbarStore {
  constructor({
    brand,
    getHost,
    website,
    links,
    userAccount,
    locales,
    localeFull,
    brandLocale,
    multicurrency,
    currency,
    localizationChannel,
    employeeChannel,
    contactForm,
    getUserAccount,
    translations
  }) {
    const isPreferredCurrencyEnabled = multicurrency.isMulticurrencyEnabled && !isAirtickets;

    extendObservable(this, {
      employee: null,
      account: null
    });

    Object.assign(this, {
      brand,
      translations,
      website,
      brandLocale,
      contactForm,
      host: getHost(),
      preferredLocale: localeFull,
      preferredCurrency: currency.iso_currency,
      links,
      locales,
      defaultLocale: localeFull,
      currencies: multicurrency.availableCurrencies,
      isPreferredCurrencyEnabled,
      defaultCurrency: currency.iso_currency,
      localizationChannel,
      getUserAccount
    });

    if (userAccount) {
      this.fetchAccountDetails();
    }

    if (employeeChannel) {
      employeeChannel.subscribe('update', (employee) => {
        this.employee = employee;
      });
    }
    eventTrengoBeacon();
  }

  fetchAccountDetails = async () => {
    try {
      const {body, status} = await this.getUserAccount();
      if (status === 200) {
        this.account = body.response.result.accounts[0];
      } else if (status === 401) {
        localStorage.removeItem('user');
      }
    } catch {
      localStorage.removeItem('user');
    }
  };
}

decorate(NavbarStore, {
  fetchAccountDetails: action
});

export default NavbarStore;
