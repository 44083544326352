import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {observer} from 'mobx-react';
import ModalInfo from 'components/modals/ModalInfo';
import ModalMobileInfo from 'components/modals/ModalMobileInfo';
import TabsAccount from 'User/Account/TabsAccount';

const AccountModal = ({isMobile, email, children}) => {
  const [showModal, setShowModal] = useState(false);
  const [tabIndexSelected, setTabIndexSelected] = useState(0);

  const TypeModal = isMobile ? ModalMobileInfo : ModalInfo;

  const onChangeTab = (index) => {
    setTabIndexSelected(index);
  };

  const toggleModal = (tabIndex) => {
    setShowModal((showModal) => !showModal);
    if ([0, 1].includes(tabIndex)) {
      setTabIndexSelected(tabIndex);
    }
  };

  const onClickAccount = (e) => {
    const el = e.target.closest('a');
    if (el && e.currentTarget.contains(el)) {
      e.preventDefault();
      setTabIndexSelected(parseInt(el.getAttribute('tab')));
      toggleModal();
    }
  };

  return (
    <>
      <div>
        {children(toggleModal, onClickAccount)}
      </div>
      <TypeModal
        show={showModal}
        title=""
        toggleModal={toggleModal}
        attrs={{bsSize: 'sm'}}
      >
        <div className="px-4 pb-4">
          <TabsAccount
            isModal
            tabIndexSelected={tabIndexSelected}
            email={email}
            onChangeTab={onChangeTab}
          />
        </div>
      </TypeModal>
    </>
  );
};

AccountModal.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  email: PropTypes.string,
  children: PropTypes.func.isRequired
};

export default observer(AccountModal);
